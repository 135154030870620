@import 'styles/helpers';

.wifiQrWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: calcRem(10);

  &.noInstruction {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
}

.richText {
  font-size: calcRem(14);
}