@import './functions';

$base-font-size: 16px;

$primary-color: #F22D31;
$primary-color-dark: #660608;
$primary-text-color: #110D0F;
$secondary-text-color: #4B4B4B;
$hint-text-color: #797878;
$light-border-color: #D7D7D3;
$body-background-color: #FDFDFC;
$tag-background-color: #F5F5F5;

$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;