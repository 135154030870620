@import 'styles/helpers';

.orderlyInfoWithLabels {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calcRem(10);

  li {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: calcRem(0);
    align-items: baseline;

    .label {
      font-size: calcRem(14);
      color: $secondary-text-color;
      letter-spacing: -0.2px;
    }

    .value {
      font-size: calcRem(18);
      font-weight: 500;
    }

    .valueIconsList {
      display: flex;
      flex-wrap: wrap;
      
      span {
        display: inline-block;
        margin-right: calcRem(10);
      }
    }
  }
}