@import 'styles/helpers';

.elementCardRoot {
  width: 100%;
  padding: calcRem(30) calcRem(20);
}

.cardHeader {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: calcRem(10);
  padding-bottom: calcRem(15);
  margin-bottom: calcRem(15);
  border-bottom: 1px solid $light-border-color;

  svg {
    fill: $primary-color;
  }

  span {
    font-weight: 800;
    color: $secondary-text-color;
    text-transform: uppercase;
  }
}

.cardContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calcRem(15);
}