.tagElementsCarousel {
  padding: calcRem(20) calcRem(5);

  .tagElementPreviewCard {
    border: 1px solid darken($tag-background-color, 5%);
    width: 100%;
    display: flex;
  }
  
  .tagElementPreviewWrapper {
    background-color: $tag-background-color;
    padding: calcRem(30) calcRem(10);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .tagElementsCarouselCta {
    display: flex;
    justify-content: center;
    padding: calcRem(0) calcRem(30);
  }
}

.presentationContentBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: calcRem(80);
  padding-top: calcRem(80);
  padding-bottom: calcRem(80);

  @media (max-width: 1000px) {
    grid-gap: calcRem(40);
    grid-template-columns: 1fr;
    padding-top: calcRem(60);
    padding-bottom: calcRem(60);
    overflow: hidden;
  }
}

.presentationContent {
  order: 1;
  padding-left: calcRem(90);

  @media(max-width: 1000px) {
    padding: 0;
  }

  @media (max-width: $breakpoint-sm) {
    padding: 0;
    order: 2;
  }
}

.presentationGraphic {
  order: 2;

  @media (max-width: $breakpoint-sm) {
    order: 1;
  }
  
  &.shunt {
    @media (max-width: $breakpoint-sm) {
      margin-left: calcRem(-100);
      margin-right: calcRem(0);
    }
  }
}

.presentationAlternate {

  .presentationContent {
    order: 2;
    padding-left: 0;
    padding-right: calcRem(90);

    @media (max-width: $breakpoint-sm) {
      padding: 0;
    }
  }
  
  .presentationGraphic {
    order: 1;

    &.shunt {
      @media (max-width: $breakpoint-sm) {
        margin-right: calcRem(-100);
        margin-left: calcRem(0);
      }
    }
  }
}

.presentationStripe {
  background-color: $tag-background-color;
}

.graphicBlobMask {
  mask-repeat: no-repeat;
  mask-image: url('../public/svgAssets/blobMask-sectionGraphic-min.svg');
  mask-size: contain;
  mask-position: center;
  margin-top: calcRem(-40);
  margin-bottom: calcRem(-40);
}

.ctaBoxStripe {

  @media (max-width: $breakpoint-sm) {
    padding: 0 calcRem(15);
  }
}

.ctaBoxStripeCard {
  background-color: $primary-color-dark;
  position: relative;
  display: grid;
  grid-template-columns: 1fr calcRem(720) 3fr;
  grid-gap: calcRem(40);
  border-radius: calcRem(10);
  
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
}

.ctaBoxStripeContent {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  grid-gap: calcRem(40);
  padding-top: calcRem(80);
  padding-bottom: calcRem(80);
  
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    padding: calcRem(20) calcRem(40) 0;
    grid-gap: calcRem(20);
  }
}

.ctaBoxGraphic {
  mask-repeat: no-repeat;
  mask-image: url('../public/svgAssets/cta-box-mask-blob-min.svg');
  mask-size: contain;
  mask-position: bottom right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: calcRem(-20);
}