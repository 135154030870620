@import 'styles/helpers';

.contentSeparator {
  margin: calcRem(10) 0;
  width: 100%;
  height: 1px;
  display: block;
  background-color: $light-border-color;
}

.imageContainer {

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}

.emptyPlaceholder {
  padding: calcRem(30);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
}