@import 'styles/helpers';

.instructions {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calcRem(15);
}

.singleItem {
  list-style-type: none;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: calcRem(10);
  position: relative;

  &:before {
    position: absolute;
    z-index: 1;
    width: 2px;
    left: calcRem(15);
    display: block;
    content: '';
    background-color: $primary-color;
    top: calcRem(30);
    bottom: calcRem(-20);
  }

  &:last-child {
    
    &:before {
      display: none;
    }
  }
}

.labelContainer {
  width: calcRem(32);
  height: calcRem(32);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid $primary-color;
  font-weight: bold;
  color: $primary-color;
  font-size: calcRem(14);
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.contentContainer {
  padding-top: calcRem(3);
}