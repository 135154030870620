@import 'styles/helpers';

.basicQrWrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: calcRem(10);

  &.noInstruction {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
}

.richText {
  font-size: calcRem(14);

  @media print {
    font-size: 10pt;
  }
}