@import 'styles/helpers';

.richText {
  font-size: calcRem(14);

  @media print {
    font-size: 10pt;
  }
}

.ltr {

}

.rtl {

}

.editorPlaceholder {

}

.editorParagraph {
  font-size: calcRem(14);
  margin: calcRem(8) 0;

  @media print {
    font-size: 10pt;
  }
}

.editQuote {

}

.editorHeadingH1, .richText h1 {
  font-size: calcRem(32);
  margin: calcRem(10) 0 calcRem(15);
  line-height: 120%;
}

.editorHeadingH2 {
  font-size: calcRem(24);
  margin: calcRem(5) 0 calcRem(15);
  line-height: 120%;
}

.editorNestedListitem {
  margin: calcRem(10) 0;
}

.editorListOl {
  padding: 0 0 0 calcRem(30);

  li {
    margin: calcRem(8) 0;
  }
}

.editorListUl {
  padding: 0 0 0 calcRem(30);

  li {
    margin: calcRem(8) 0;
  }
}

.editorListitem {

}

.editorImage {

}

.editorLink {
  color: $primary-color;
  text-decoration: underline;
}

.editorTextBold {
  font-weight: bold;
}

.editorTextItalic {
  font-style: italic;
}

.editorTextOverflowed {
  
}

.editorTextHashtag {

}

.editorTextUnderline {

}

.editorTextStrikethrough {

}

.editorTextUnderlineStrikethrough {

}

.editorTextCode {

}
