@import './variables';
@import './shadows';

.container {
  max-width: calcRem(1180);
  width: 100%;
  margin: 0 auto;
  padding-left: calcRem(30);
  padding-right: calcRem(30);
}

.containerWide {
  max-width: calcRem(1360);
  width: 100%;
  margin: 0 auto;
  padding-left: calcRem(30);
  padding-right: calcRem(30);
}

.containerNarrow {
  max-width: calcRem(720);
  width: 100%;
  margin: 0 auto;
  padding-left: calcRem(30);
  padding-right: calcRem(30);
}

@import './commonContent';